




import { Component, Prop, Vue } from 'vue-property-decorator';
import { Placeholder } from '@/statics/placeholders';
import WysiwygEditor from '@/components/WysiwygEditor.vue';

@Component({
  components: { WysiwygEditor }
})
export default class RichEditor extends Vue {
  @Prop({ type: String }) value?: string;
  @Prop({ type: Array, default: () => [] }) placeholders!: Placeholder[];

  config = {
    extraPlugins: 'emailAutotag',
    placeholders: this.placeholders
  };

  get content(): string | undefined {
    return this.value;
  }

  set content(content: string | undefined) {
    this.$emit('input', content);
  }
}
