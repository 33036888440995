





















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { UPDATE_EMAIL_TEMPLATE } from '../store';
import EmailEditor from './EmailEditor.vue';
import { emailPlaceholder, Placeholder } from '@/statics/placeholders';
import { EmailTemplate, WriteEmailTemplate } from '../types';
import { ApiResponse } from '@/components/types';
import { MapStateEmailTemplateToWriteEmailTemplate } from '../model';
import PlaceholderTable from './PlaceholderTable.vue';
import ReceiverTypeSelect from './ReceiverTypeSelect.vue';
import VisibilitySelect from './VisibilitySelect.vue';

const Correspondence = namespace('correspondence');

@Component({
  components: {
    PlaceholderTable,
    EmailEditor,
    VisibilitySelect,
    ReceiverTypeSelect
  }
})
export default class UpdateEmailTemplateDialog extends Vue {
  @Prop({ type: Object }) emailTemplate?: EmailTemplate;

  @Correspondence.Action(UPDATE_EMAIL_TEMPLATE) updateTemplate!: (letterTemplate: WriteEmailTemplate) => Promise<ApiResponse>;

  error: any = null;
  loading: boolean = false;
  dialog: boolean = false;
  value: WriteEmailTemplate | null = null;

  cancel(observer?: any) {
    this.dialog = false;

    setTimeout(() => {
      this.value = null;
      this.$emit('update:emailTemplate', null);
      this.error = null;
      observer && observer.reset();
    }, 200);
  }

  @Watch('emailTemplate')
  watchTemplate(emailTemplate: EmailTemplate) {
    if (!emailTemplate) return;

    this.value = MapStateEmailTemplateToWriteEmailTemplate(emailTemplate);

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  get placeholders(): Placeholder[] {
    if (!this.value) return [];

    return emailPlaceholder(
      this.value.requirements.includes('conservatorship'),
      this.value.requirements.includes('bank_account')
    );
  }

  async save() {
    this.error = null;

    if (!this.value) return;

    return this.updateTemplate(this.value);
  }
}
