

































































import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_EMAIL_TEMPLATES, SET_EMAIL_TEMPLATE_PAGE } from '../store';
import { PaginatableMixin } from '@/views/mixin';
import { ApiResponse, Pagination } from '@/components/types';
import { EmailTemplate, EmailTemplateList } from '@/modules/correspondence/types';
import ErrorAlert from '@/components/ErrorAlert.vue';
import CreateEmailTemplateDialog from '../components/CreateEmailTemplateDialog.vue';
import UpdateEmailTemplateDialog from '../components/UpdateEmailTemplateDialog.vue';
import DeleteEmailTemplateDialog from '../components/DeleteEmailTemplateDialog.vue';
import AppTable from '@/components/AppTable.vue';

const Correspondence = namespace('correspondence');

@Component({
  components: { AppTable, DeleteEmailTemplateDialog, UpdateEmailTemplateDialog, CreateEmailTemplateDialog, ErrorAlert }
})
export default class List extends PaginatableMixin {
  @Correspondence.State('emailTemplates') list!: EmailTemplateList;
  @Correspondence.Action(FETCH_EMAIL_TEMPLATES) fetch!: () => Promise<ApiResponse>;
  @Correspondence.Mutation(SET_EMAIL_TEMPLATE_PAGE) setPage!: (page: number) => void;

  loading: boolean = false;
  error: any = false;

  updateTemplate: EmailTemplate | null = null;
  deleteTemplate: EmailTemplate | null = null;

  pagination: Pagination = {
    sortBy: ['created'],
    itemsPerPage: 10,
    page: 1,
    descending: true
  };

  get items(): EmailTemplate[] {
    return this.list.items;
  }

  get headers() {
    return [
      { align: 'left', text: this.$i18n.t('common.name'), value: 'name' },
      { align: 'left', text: this.$i18n.t('common.visibility'), value: 'visibility' },
      { align: 'left', text: this.$i18n.t('common.creator'), value: 'creator' },
      ...(this.$vuetify.breakpoint.mdAndUp ? [{
        align: 'left',
        text: this.$i18n.t('common.actions'),
        value: null
      }] : [])
    ];
  }

  async created() {
    try {
      this.loading = true;

      await this.fetch();
    } catch (error) {
      this.error = error;
    }

    this.loading = false;
  }
}
