



































































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ADD_EMAIL_TEMPLATE } from '../store';
import { emailPlaceholder, Placeholder } from '@/statics/placeholders';
import { ApiResponse } from '@/components/types';
import PlaceholderTable from '../components/PlaceholderTable.vue';
import ReceiverTypeSelect from './ReceiverTypeSelect.vue';
import VisibilitySelect from './VisibilitySelect.vue';
import EmailEditor from './EmailEditor.vue';
import { WriteEmailTemplate } from '../types';
import { createEmailTemplate } from '../model';

const Correspondence = namespace('correspondence');

@Component({
  components: {
    PlaceholderTable,
    EmailEditor,
    VisibilitySelect,
    ReceiverTypeSelect
  }
})
export default class CreateEmailTemplateDialog extends Vue {
  @Correspondence.Action(ADD_EMAIL_TEMPLATE) addTemplate!: (template: WriteEmailTemplate) => Promise<ApiResponse>;

  error: any = null;
  loading: boolean = false;
  dialog: boolean = false;
  value: WriteEmailTemplate = createEmailTemplate(this.$auth.user().id);

  get placeholders(): Placeholder[] {
    return emailPlaceholder(
      this.value.requirements.includes('conservatorship'),
      this.value.requirements.includes('bank_account')
    );
  }

  cancel(observer?: any) {
    this.dialog = false;

    setTimeout(() => {
      this.value = createEmailTemplate(this.$auth.user().id);
      this.error = null;
      observer && observer.reset();
    }, 200);
  }

  save() {
    this.error = null;

    return this.addTemplate(this.value);
  }
}
