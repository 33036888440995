
































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { DELETE_EMAIL_TEMPLATE } from '../store';
import { EmailTemplate, WriteEmailTemplate } from '../types';
import { ApiResponse } from '@/components/types';
import { MapStateEmailTemplateToWriteEmailTemplate } from '../model';
import ErrorAlert from '@/components/ErrorAlert.vue';

const Correspondence = namespace('correspondence');

@Component({
  components: { ErrorAlert }
})
export default class DeleteEmailTemplateDialog extends Vue {
  @Prop({ type: Object }) emailTemplate?: EmailTemplate;

  @Correspondence.Action(DELETE_EMAIL_TEMPLATE) deleteTemplate!: (letter: WriteEmailTemplate) => Promise<ApiResponse>;

  error: any = null;
  loading: boolean = false;
  dialog: boolean = false;
  value: WriteEmailTemplate | null = null;

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value !== false) return;

    setTimeout(() => {
      this.error = null;
      this.dialog = false;
      this.value = null;
      this.$emit('update:emailTemplate', null);
    }, 200);
  }

  @Watch('emailTemplate')
  watchLetter(emailTemplate: EmailTemplate) {
    if (!emailTemplate) return;

    this.value = MapStateEmailTemplateToWriteEmailTemplate(emailTemplate);

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  async save() {
    if (!this.value) return;

    this.error = null;
    this.loading = true;

    const { error } = await this.deleteTemplate(this.value);

    this.loading = false;

    if (error) {
      this.error = error;
      return;
    }

    this.dialog = false;
  }
}
